import { Button, Center, Container, Heading, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { ClientIds, clientSettings } from 'src/clientSetting/settings'
import { CenteredButtonContainer } from 'src/layout/CenteredButtonContainer'
import { PagePadding } from 'src/layout/PagePadding'
import { PageRoot } from 'src/layout/PageRoot'
import { Footer } from 'src/navigation/Footer'
import { Header } from 'src/navigation/Header'
import ErrorIcon from './ErrorIcon.svg'
import { StaticClientSettingProvider } from 'src/clientSetting/Provider'
import Head from 'next/head'

const extractClientId = (url: string): ClientIds => {
  const u = new URL(`http://localhost:3000${url}`) // NOTE: URL は絶対パスでないとエラーになるのでダミーのスキームを付与する
  return (u.searchParams.get('client_id') as ClientIds) || clientSettings.default.id
}

export const NotFound: FC<{}> = ({ ...props }) => {
  const router = useRouter()
  const clientId = extractClientId(router.asPath)

  return (
    <StaticClientSettingProvider client={clientSettings[clientId]}>
      <Head>
        <title>{clientSettings[clientId]?.headerTitle || 'ページが見つかりませんでした'}</title>
      </Head>
      <PageRoot>
        <Header />
        <Container>
          <Center mt="60px">
            <ErrorIcon />
          </Center>
          <Heading textAlign="center" fontSize="lg" mt="40px">
            ページが見つかりませんでした
          </Heading>
          <Center my={6}>
            <Text maxWidth="280px">
              お探しのページはURLが間違っているか、削除された可能性があります。
            </Text>
          </Center>
          <CenteredButtonContainer mt={6}>
            <NextLink href={clientSettings[clientId]?.notFoundRedirectTo || ''}>
              <Button width="100%" variant="outline" size="lg">
                トップページへ
              </Button>
            </NextLink>
          </CenteredButtonContainer>
        </Container>
        <PagePadding />
        <Footer withLinks />
      </PageRoot>
    </StaticClientSettingProvider>
  )
}
