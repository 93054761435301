export const PRIVACY_POLICY = 'https://curon.co/static/app/privacy.html'
export const MICIN_PRIVACY_POLICY = 'https://micin.jp/privacy'
export const TERMS = '/terms.html'
export const CONTACT = 'https://app.curon.co/contact'

export const HELP_MAIL_NOT_DELIVERED = 'https://micin.force.com/FAQforPatients/s/article/1452'

export const FAQ_FOR_PATIENT = 'https://micin.force.com/FAQforPatients/s/'
export const SPECIFIED_COMMERCIAL = 'https://micin.jp/law'

export const SECURITY_WHITE_PAPER = '/whitepaper.pdf'
