import { Box, ChakraProps, Link } from '@chakra-ui/react'
import { FC } from 'react'
import {
  PRIVACY_POLICY,
  TERMS,
  FAQ_FOR_PATIENT,
  SPECIFIED_COMMERCIAL,
  SECURITY_WHITE_PAPER,
  MICIN_PRIVACY_POLICY,
} from 'src/urls'

export const Footer: FC<{ withLinks?: boolean } & ChakraProps> = ({ withLinks, ...props }) => {
  return (
    <Box backgroundColor="gray.50" py={4} textAlign="center" {...props}>
      {withLinks ? (
        <>
          <Box mt={2} color="green.600">
            <Link href={TERMS} isExternal>
              利用規約
            </Link>
            ・
            <Link href={MICIN_PRIVACY_POLICY} isExternal>
              プライバシーポリシー
            </Link>
          </Box>
          <Box mt={4} color="green.600">
            <Link href={PRIVACY_POLICY} isExternal>
              オンライン医療サービスの個人情報の取り扱いについて
            </Link>
          </Box>
          <Box mt={4} color="green.600">
            <Link href={SECURITY_WHITE_PAPER} isExternal>
              セキュリティホワイトペーパー
            </Link>
            ・
            <Link href={FAQ_FOR_PATIENT} isExternal>
              よくあるお問い合わせ
            </Link>
          </Box>
          <Box mt={4} color="green.600">
            <Link href={SPECIFIED_COMMERCIAL} isExternal>
              特定商取引法に基づく表記
            </Link>
          </Box>
          <Box color="gray.600" mt={5}>
            © MICIN,Inc.
          </Box>
        </>
      ) : (
        <>
          <Box color="gray.600">© MICIN,Inc.</Box>
        </>
      )}
    </Box>
  )
}
